import React from 'react'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from 'shared/components/form/field-error-and-description'
import ImageUploader, { ImageUploaderProps } from 'shared/components/image-uploader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import UploadIcon from 'shared/icons/upload-icon'
import { FileSelectorVariantEnum } from '../file-selector/constants'
import { FieldLabel, FieldLabelProps } from '../form/field-label'

type ImageUploaderWithPreviewProps = FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    previewProps: React.ComponentProps<'img'>
    onRemove?: () => void
    imageLoaderProps: ImageUploaderProps
  }

export const ImageUploaderWithPreview = ({
  previewProps: { className: previewClassName, ...restPreviewProps },
  label,
  labelClassName,
  required,
  onRemove,
  imageLoaderProps,
  error,
  description,
}: ImageUploaderWithPreviewProps) => {
  const { t } = useLocoTranslation()
  return (
    <div className={'flex gap-1 flex-col'}>
      <FieldLabel label={label} required={required} labelClassName={labelClassName} />
      {restPreviewProps.src ? (
        <div className={'flex'}>
          <img className={`min-w-0 object-cover ${previewClassName || ''}`} {...restPreviewProps} />
          <CloseIcon
            className="shrink-0 cursor-pointer hover:fill-darkblue"
            fill="#CBD5E1"
            width="21px"
            height="21px"
            onClick={onRemove}
          />
        </div>
      ) : (
        <ImageUploader
          label={
            <div className={'flex gap-1'}>
              <UploadIcon />
              <span className={'text-blue'}>{t('global.choose_file')}</span>
            </div>
          }
          icon={null}
          variant={FileSelectorVariantEnum.big}
          {...imageLoaderProps}
        />
      )}
      <FieldErrorAndDescription description={description} error={error} />
    </div>
  )
}
