import { CUSTOM_DOMAINS_SETTINGS_API } from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import { CustomDomainsLoginPageApiInterface } from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'

export const useCustomDomainPageApi = (domainId: number) => {
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    CustomDomainsLoginPageApiInterface
  >({
    method: RequestMethodsEnum.post,
  })

  const getLoginPageUrl = () =>
    postFetcher(`${CUSTOM_DOMAINS_SETTINGS_API}/login-page-url/${domainId}`)
  const get404PageUrl = () =>
    postFetcher(`${CUSTOM_DOMAINS_SETTINGS_API}/not-found-page-url/${domainId}`)

  return { getLoginPageUrl, get404PageUrl }
}
