import React from 'react'

function UploadIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.6665 12.3333L9.99984 9M9.99984 9L13.3332 12.3333M9.99984 9V16.5M16.6665 12.9524C17.6844 12.1117 18.3332 10.8399 18.3332 9.41667C18.3332 6.88536 16.2811 4.83333 13.7498 4.83333C13.5677 4.83333 13.3974 4.73833 13.3049 4.58145C12.2182 2.73736 10.2119 1.5 7.9165 1.5C4.46472 1.5 1.6665 4.29822 1.6665 7.75C1.6665 9.47175 2.36271 11.0309 3.48896 12.1613"
        stroke="#00A0FF"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UploadIcon
