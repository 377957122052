import { removeDomain } from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import { CustomDomainsSettingsInterface } from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import React from 'react'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface RemoveDomainModalInterface {
  opened: boolean
  onClose: () => void
  domain: CustomDomainsSettingsInterface
  removeDomainWithUpdate: () => Promise<void>
}

const RemoveDomainModal = ({
  opened,
  onClose,
  domain,
  removeDomainWithUpdate,
}: RemoveDomainModalInterface) => {
  const { t } = useLocoTranslation()

  const onRemove = async () => {
    await removeDomain(domain.id)
    await removeDomainWithUpdate()
  }
  return (
    <ConfirmationModal
      opened={opened}
      onClose={onClose}
      onConfirm={onRemove}
      confirmationText={t('dashboard.settings.custom_domains.remove_confirmation', {
        user_domain: domain.domainName,
      })}
      title={t('confirmation_modal.default_title')}
    />
  )
}

export default RemoveDomainModal
