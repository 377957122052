import { useCustomDomainPageApi } from 'modules/profile/custom-domains-settings/hooks/use-custom-domain-page-api'
import React, { useState } from 'react'
import { NeutralButton } from 'shared/components/button'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface DomainLoginPageButtonProps {
  domainId: number
}

const DomainLoginPageButton = ({ domainId }: DomainLoginPageButtonProps) => {
  const { t } = useLocoTranslation()

  const { getLoginPageUrl } = useCustomDomainPageApi(domainId)

  const [isFetching, setIsFetching] = useState(false)

  return (
    <NeutralButton
      type={'button'}
      isFetching={isFetching}
      onClick={async () => {
        try {
          setIsFetching(true)
          const responseData = await getLoginPageUrl()
          window.location.assign(responseData.loginPageUrl)
        } catch (e) {
        } finally {
          setIsFetching(false)
        }
      }}
    >
      {t('dashboard.settings.custom_domains.login_page_edit')}
    </NeutralButton>
  )
}

export default DomainLoginPageButton
