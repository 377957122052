import {
  CustomDomainsCreateInterface,
  CustomDomainsEditRequestPayloadInterface,
  CustomDomainsSettingId,
  CustomDomainsSettingsInterface,
  CustomDomainsSubdomainEditInterface,
} from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import httpClient from 'shared/utils/http-client'

export const CUSTOM_DOMAINS_SETTINGS_API = '/api/dashboard/customer/settings/domains'

export const createDomain = (data: CustomDomainsCreateInterface) =>
  httpClient.post<CustomDomainsSettingsInterface>(`${CUSTOM_DOMAINS_SETTINGS_API}/create`, data)

export const editDomain = (
  id: CustomDomainsSettingId,
  data: CustomDomainsEditRequestPayloadInterface,
) => httpClient.put(`${CUSTOM_DOMAINS_SETTINGS_API}/${id}`, data)

export const removeDomain = (id: CustomDomainsSettingId) =>
  httpClient.delete(`${CUSTOM_DOMAINS_SETTINGS_API}/${id}`)

export const markAsDefault = (id: CustomDomainsSettingId) =>
  httpClient.post(`${CUSTOM_DOMAINS_SETTINGS_API}/set-default/${id}`)

export const removeLoginPage = (id: CustomDomainsSettingId) =>
  httpClient.post(`${CUSTOM_DOMAINS_SETTINGS_API}/set-default-login-page/${id}`)

export const editSubDomain = (
  id: CustomDomainsSettingId,
  data: CustomDomainsSubdomainEditInterface,
) => httpClient.post(`${CUSTOM_DOMAINS_SETTINGS_API}/edit-subdomain-name/${id}`, data)

export const removeNotFoundPage = (id: CustomDomainsSettingId) =>
  httpClient.post(`${CUSTOM_DOMAINS_SETTINGS_API}/set-default-not-found-page/${id}`)
