import React from 'react'

function RecaptchaIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="#CBD5E1"
      stroke="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5852 11.6602C22.5847 11.5054 22.5811 11.3515 22.5742 11.1983V2.46357L20.1594 4.87836C18.183 2.4592 15.1765 0.914062 11.8088 0.914062C8.30428 0.914062 5.1908 2.58687 3.22266 5.17747L7.18079 9.17724C7.56868 8.45985 8.11974 7.84368 8.78416 7.37831C9.47519 6.83906 10.4543 6.39814 11.8087 6.39814C11.9723 6.39814 12.0986 6.41726 12.1914 6.45329C13.8696 6.58574 15.3242 7.51186 16.1806 8.85605L13.3789 11.6578C16.9277 11.6439 20.9367 11.6357 22.5849 11.6596"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7462 0.914062C11.5914 0.914583 11.4374 0.918152 11.2842 0.925092H2.54952L4.96431 3.33988C2.54515 5.31625 1 8.32282 1 11.6904C1 15.195 2.67284 18.3085 5.26342 20.2766L9.26318 16.3185C8.54579 15.9306 7.92962 15.3796 7.46425 14.7151C6.92502 14.0241 6.48408 13.045 6.48408 11.6906C6.48408 11.5269 6.5032 11.4006 6.53923 11.3079C6.67168 9.62972 7.5978 8.17511 8.94199 7.31862L11.7438 10.1204C11.7298 6.57161 11.7216 2.5626 11.7456 0.914382"
        stroke="none"
      />
      <path
        d="M11.7462 0.914062C11.5914 0.914583 11.4374 0.918152 11.2842 0.925092H2.54952L4.96431 3.33988C2.54515 5.31625 1 8.32282 1 11.6904C1 15.195 2.67284 18.3085 5.26342 20.2766L9.26318 16.3185C8.54579 15.9306 7.92962 15.3796 7.46425 14.7151C6.92502 14.0241 6.48408 13.045 6.48408 11.6906C6.48408 11.5269 6.5032 11.4006 6.53923 11.3079C6.67168 9.62972 7.5978 8.17511 8.94199 7.31862L11.7438 10.1204C11.7298 6.57161 11.7216 2.5626 11.7456 0.914382"
        fill="none"
        strokeWidth="0.5"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.6914C1.00052 11.8462 1.00409 12.0001 1.01102 12.1533V20.8881L3.42581 18.4733C5.40217 20.8925 8.40874 22.4376 11.7764 22.4376C15.2809 22.4376 18.3944 20.7648 20.3626 18.1742L16.4044 14.1744C16.0165 14.8918 15.4655 15.508 14.8011 15.9733C14.11 16.5126 13.1309 16.9535 11.7765 16.9535C11.6129 16.9535 11.4866 16.9344 11.3938 16.8983C9.71565 16.7659 8.26103 15.8398 7.40455 14.4956L10.2063 11.6938C6.65754 11.7078 2.64851 11.7159 1.00029 11.692"
        stroke="none"
      />
      <path
        d="M1 11.6914C1.00052 11.8462 1.00409 12.0001 1.01102 12.1533V20.8881L3.42581 18.4733C5.40217 20.8925 8.40874 22.4376 11.7764 22.4376C15.2809 22.4376 18.3944 20.7648 20.3626 18.1742L16.4044 14.1744C16.0165 14.8918 15.4655 15.508 14.8011 15.9733C14.11 16.5126 13.1309 16.9535 11.7765 16.9535C11.6129 16.9535 11.4866 16.9344 11.3938 16.8983C9.71565 16.7659 8.26103 15.8398 7.40455 14.4956L10.2063 11.6938C6.65754 11.7078 2.64851 11.7159 1.00029 11.692"
        fill="none"
        strokeWidth="0.5"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default RecaptchaIcon
