import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from '../form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from '../form/field-label'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    htmlFor?: string
    nativeLabelProps?: React.ComponentProps<'label'>
    inputClassName?: string
    isPreFetching?: boolean
    addonBefore?: React.ReactNode
    addonAfter?: React.ReactNode
    success?: boolean
    addonAfterClassName?: string
    statusIcon?: React.ReactNode
    statusIconClassName?: string
  }

const FormInput = forwardRef<HTMLInputElement, InputProps>(function FormInput(
  {
    error,
    label,
    labelClassName,
    labelRightElement,
    htmlFor,
    nativeLabelProps,
    value,
    onChange,
    onFocus,
    maxLength,
    placeholder,
    isPreFetching,
    required,
    description,
    className,
    addonBefore,
    addonAfter,
    inputClassName,
    success,
    addonAfterClassName,
    statusIcon,
    statusIconClassName,
    ...rest
  },
  ref,
) {
  const addonCommonClassName = twMerge(
    'break-words flex justify-center items-center py-2.5 px-2 sm:px-4  border border-y-gray/50',
    isPreFetching ? 'bg-gray-600/40 text-transparent border-0' : 'text-gray-700 bg-gray-200',
  )

  const inputPaddingsClassName = twMerge('py-2.5 px-4', statusIcon && 'pr-9')

  return (
    <label
      className={twMerge('text-sm font-medium flex flex-col gap-1', className)}
      {...nativeLabelProps}
    >
      <FieldLabel
        label={label}
        labelClassName={labelClassName}
        labelRightElement={labelRightElement}
        required={required}
      />
      <div
        className={twMerge(
          'text-darkblue relative flex shrink-0 w-full rounded-lg main-transition-colors',
          isPreFetching && 'animate-pulse',
        )}
      >
        {addonBefore && (
          <div
            className={twMerge(
              'border-l-gray/50 border-r-transparent rounded-l-lg',
              addonCommonClassName,
            )}
          >
            {addonBefore}
          </div>
        )}
        <input
          ref={ref}
          className={twMerge(
            'basis-full min-w-0 focus:border-blue focus:outline-none placeholder:text-gray-300/70 rounded-lg',
            inputPaddingsClassName,
            inputClassName,
            error ? 'border-danger' : success ? 'border-green' : 'border-gray/30',
            isPreFetching
              ? 'bg-gray-600/40 text-transparent disabled:bg-gray-600/40 border-0'
              : 'disabled:bg-gray-200/40 border',
            addonBefore && 'rounded-l-none',
            addonAfter && 'rounded-r-none',
          )}
          type={rest.type || 'text'}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          maxLength={maxLength}
          placeholder={placeholder}
          spellCheck={false}
          disabled={rest.disabled || isPreFetching}
          {...rest}
        />
        {addonAfter && (
          <div
            className={twMerge(
              `border-r-gray/50 border-l-transparent rounded-r-lg`,
              addonCommonClassName,
              addonAfterClassName,
            )}
          >
            {addonAfter}
          </div>
        )}
        {statusIcon && (
          <div
            className={twMerge(
              'absolute right-3 w-[21px] h-[21px] translate-y-1/2',
              statusIconClassName,
            )}
          >
            {statusIcon}
          </div>
        )}
      </div>
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
})

export const handleNumberInput = (value: string, maxValue?: number) => {
  const number = Number(value.replace(/\D/g, ''))
  return maxValue ? Math.min(number, maxValue) : number
}

export const handlePositiveOptionalNumberInput = (value: string, maxValue?: number) => {
  const str = value.replace(/\D/g, '')
  const number = +str
  return maxValue ? Math.min(number, maxValue) : str === '' ? str : number
}

export default FormInput
