export type FileInputAcceptType = string

export const fileInputAcceptTypes: Record<string, FileInputAcceptType[]> = {
  image: ['image/*'],
  csv: ['csv/*'],
}

export const isAcceptTypeValid = (
  fileAcceptType: FileInputAcceptType,
  fileInputAcceptTypes: FileInputAcceptType[],
) =>
  fileInputAcceptTypes.some(fileInputAcceptType => RegExp(fileInputAcceptType).test(fileAcceptType))

export const acceptType = (fileAccept: FileInputAcceptType[]) =>
  fileAccept.join(', ').replace(/[/*]/g, '')
