import React from 'react'

function PictureIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="#CBD5E1"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5527 17.1788V2.74891C18.5527 1.61513 17.6251 0.6875 16.4913 0.6875H2.06141C0.92763 0.6875 0 1.61514 0 2.74891V17.1788C0 18.3126 0.92764 19.2402 2.06141 19.2402H16.4913C17.6251 19.2402 18.5527 18.3126 18.5527 17.1788ZM5.66899 11.5109L8.24576 14.6133L11.8532 9.96484L16.4914 16.1491H2.06152L5.66899 11.5109Z"
      />
    </svg>
  )
}

export default PictureIcon
