import { LocaleEnum } from 'shared/enums/locale-enum'

export const getDomainInfoLink = (locale?: LocaleEnum) => {
  switch (locale) {
    case LocaleEnum.SPANISH: {
      return 'https://help-es.systeme.io/article/677-integrando-tu-dominio-raiz-en-systeme-io'
    }
    case LocaleEnum.FRENCH: {
      return 'https://aide.systeme.io/article/52-comment-connecter-votre-nom-de-domaine-a-systeme-io'
    }
    case LocaleEnum.ITALIAN: {
      return 'https://help-it.systeme.io/article/488-integrare-il-tuo-dominio-principale-in-systeme-io'
    }
    case LocaleEnum.PORTUGUESE: {
      return 'https://help-pt.systeme.io/article/748-integrando-seu-dominio-raiz-a-systeme-io'
    }
    case LocaleEnum.DEUTSCH: {
      return 'https://help-de.systeme.io/article/2042-so-fuegen-sie-eine-benutzerdefinierte-domain-zu-ihrem-systeme-io-konto-hinzu'
    }
    default: {
      return 'https://help.systeme.io/article/135-integrating-your-root-domain-into-systeme-io'
    }
  }
}
