import { editDomain } from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import DomainLoginPageButton from 'modules/profile/custom-domains-settings/components/domain-login-page-button'
import DomainNotFoundPageButton from 'modules/profile/custom-domains-settings/components/domain-not-found-page-button'
import { CustomDomainsStatusEnum } from 'modules/profile/custom-domains-settings/enums/custom-domains-settings-enum'
import { CustomDomainErrorResponseContentInterface } from 'modules/profile/custom-domains-settings/types/custom-domain-errors-interface'
import {
  CustomDomainsEditErrorsInterface,
  CustomDomainsEditInterface,
  CustomDomainsSettingsInterface,
} from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { NeutralButton } from 'shared/components/button'
import { PrimaryButton } from 'shared/components/button'
import ConfirmationModal from 'shared/components/confirmation-modal'
import { FileSelectorVariantEnum } from 'shared/components/file-selector/constants'
import FormInput from 'shared/components/form-input'
import { Hint } from 'shared/components/hint'
import { ImageUploaderWithPreview } from 'shared/components/image-uploader/image-uploader-with-preview'
import Modal from 'shared/components/modal'
import TransLoco from 'shared/components/trans-loco'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import PictureIcon from 'shared/icons/picture-icon'
import RecaptchaIcon from 'shared/icons/recapthca-icon'
import SettingsIcon from 'shared/icons/settings-icon'

interface DomainSettingsModalInterface {
  opened: boolean
  onClose: () => void
  domain: CustomDomainsSettingsInterface
  onRemoveLoginPage: (domain: CustomDomainsSettingsInterface) => Promise<void>
  editDomainWithUpdate: (data: CustomDomainsSettingsInterface) => Promise<void>
  onRemoveNotFoundPage: (domain: CustomDomainsSettingsInterface) => Promise<void>
}

const defaultValues: CustomDomainsEditInterface = {
  faviconFile: null,
  domainCaptchaSettings: { openKey: '', privateKey: '' },
  facebookConversionPixelSettings: { pixel: '', token: '' },
  logoFile: null,
  emailForSupport: null,
}

const defaultErrors: CustomDomainsEditErrorsInterface = {
  facebookConversionPixelSettings: { pixel: '', token: '' },
  faviconFile: '',
  logoFile: '',
  common: '',
  domainCaptchaSettings: { openKey: '', privateKey: '' },
  emailForSupport: '',
}

const DomainSettingsModal = ({
  domain,
  opened,
  onClose,
  onRemoveLoginPage,
  editDomainWithUpdate,
  onRemoveNotFoundPage,
}: DomainSettingsModalInterface) => {
  const { t } = useLocoTranslation()
  const [isFetching, setIsFetching] = useState(false)
  const [tempState, setTempState] = useState<CustomDomainsEditInterface>(defaultValues)
  const [errors, setErrors] = useState<CustomDomainsEditErrorsInterface>(defaultErrors)
  const [isDeleteLoginOpen, setIsDeleteLoginOpen] = useState(false)
  const [isDeleteNotFoundOpen, setIsDeleteNotFoundOpen] = useState(false)

  useEffect(() => {
    setTempState(prev => ({
      ...prev,
      logoFile: domain.logoFile,
      faviconFile: domain.faviconFile,
      emailForSupport: domain.emailForSupport,
      facebookConversionPixelSettings: domain.facebookConversionPixelSettings
        ? {
            pixel: domain.facebookConversionPixelSettings.pixel,
            token: domain.facebookConversionPixelSettings.token,
          }
        : { pixel: '', token: '' },
      domainCaptchaSettings: domain.domainCaptchaSettings
        ? {
            privateKey: domain.domainCaptchaSettings.privateKey,
            openKey: domain.domainCaptchaSettings.openKey,
          }
        : { privateKey: '', openKey: '' },
    }))
  }, [domain, opened])

  const onEdit = async () => {
    try {
      setIsFetching(true)
      setErrors(defaultErrors)
      await editDomain(domain.id, {
        ...tempState,
        logoFile: tempState.logoFile ? tempState.logoFile.id : null,
        faviconFile: tempState.faviconFile ? tempState.faviconFile.id : null,
      })
      const newData: CustomDomainsSettingsInterface = { ...domain, ...tempState }
      await editDomainWithUpdate(newData)
      setIsFetching(false)
      onClose()
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        const errors = e.errors as unknown as CustomDomainErrorResponseContentInterface
        if (errors.common) {
          const common = errors.common
          setErrors(prev => ({ ...prev, common: common.join('') }))
        }
        if (errors.fields) {
          const fields = errors.fields
          setErrors(prev => ({
            ...prev,
            logoFile: fields.logoFile?.join(''),
            domainCaptchaSettings: fields.domainCaptchaSettings
              ? {
                  openKey: fields.domainCaptchaSettings.openKey?.join(''),
                  privateKey: fields.domainCaptchaSettings.privateKey?.join(''),
                }
              : prev.domainCaptchaSettings,
            facebookConversionPixelSettings: {
              pixel: fields.facebookConversionPixelSettings?.pixel?.join(''),
              token: fields.facebookConversionPixelSettings?.token?.join(''),
            },
            faviconFile: fields.faviconFile?.join(''),
            emailForSupport: fields.emailForSupport?.join(''),
          }))
        }
      } else if (e instanceof InternalError) {
        setErrors(prev => ({ ...prev, common: t('core.error.internal_error_message') }))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const onModalClose = () => {
    setTempState(defaultValues)
    setErrors(defaultErrors)
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={t('dashboard.settings.custom_domains.domain_settings')}
        isFetching={isFetching}
        afterLeave={onModalClose}
      >
        <form className="flex flex-col gap-6 lg:gap-10">
          <div className="flex flex-col gap-5">
            {domain.status === CustomDomainsStatusEnum.active && (
              <>
                <div className={'flex flex-col gap-2'}>
                  <div className={'flex gap-1.5'}>
                    <SettingsIcon className={'h-[18px] w-[18px]'} fill="#CBD5E1" />
                    <Hint label={t('dashboard.settings.custom_domains.customize_login_page')}>
                      <span>{t('dashboard.settings.custom_domains.login_page_settings')}</span>
                    </Hint>
                  </div>
                  <div className={'flex gap-2'}>
                    <DomainLoginPageButton domainId={domain.id} />
                    {domain.loginPage?.id && (
                      <NeutralButton
                        onClick={e => {
                          e.preventDefault()
                          setIsDeleteLoginOpen(true)
                        }}
                        className={'hover:border-danger hover:text-danger'}
                      >
                        {t('dashboard.settings.custom_domains.login_page_restore')}
                      </NeutralButton>
                    )}
                  </div>
                </div>
                <hr className={'text-gray/30'} />
                <div className={'flex flex-col gap-2'}>
                  <div className={'flex gap-1.5'}>
                    <SettingsIcon className={'h-[18px] w-[18px]'} fill="#CBD5E1" />
                    <Hint label={t('dashboard.settings.custom_domains.customize_not_found_page')}>
                      <span>{t('dashboard.settings.custom_domains.not_found_page_settings')}</span>
                    </Hint>
                  </div>
                  <div className={'flex gap-2'}>
                    <DomainNotFoundPageButton domainId={domain.id} />
                    {domain.notFoundPage?.id && (
                      <NeutralButton
                        onClick={e => {
                          e.preventDefault()
                          setIsDeleteNotFoundOpen(true)
                        }}
                        className={'hover:border-danger hover:text-danger'}
                      >
                        {t('dashboard.settings.custom_domains.not_found_page_restore')}
                      </NeutralButton>
                    )}
                  </div>
                </div>
                <hr className={'text-gray/30'} />
              </>
            )}
            <div className={'flex flex-col gap-2'}>
              <ImageUploaderWithPreview
                label={
                  <div className={'flex gap-1.5'}>
                    <PictureIcon className={'h-[18px] w-[18px]'} />
                    <Hint label={t('dashboard.settings.custom_domains.favicon_description')}>
                      <span>{t('dashboard.settings.custom_domains.favicon_settings')}</span>
                    </Hint>
                  </div>
                }
                previewProps={{
                  className: 'h-[30px] w-[30px]',
                  src: tempState.faviconFile?.path,
                }}
                onRemove={() =>
                  setTempState(prev => {
                    return {
                      ...prev,
                      faviconFile: null,
                    }
                  })
                }
                imageLoaderProps={{
                  variant: FileSelectorVariantEnum.small,
                  fileSelectorDropZoneClassName: 'w-[200px] h-[30px]',
                  onUploadSuccess: dataFile =>
                    setTempState(prev => ({ ...prev, faviconFile: dataFile })),
                  source: 'settings',
                  error: errors.faviconFile,
                }}
              />
            </div>
            <hr className={'text-gray/30'} />
            <div className={'flex flex-col gap-2'}>
              <div className={'flex gap-1.5'}>
                <Hint label={t('dashboard.settings.custom_domains.tooltip_contact_us_email')}>
                  <span>{t('dashboard.settings.custom_domains.title_contact_us_email')}</span>
                </Hint>
              </div>
              <div className={'flex flex-col lg:flex-row gap-2 lg:gap-10 [&>*]:flex-1'}>
                <FormInput
                  value={tempState.emailForSupport || ''}
                  onChange={e =>
                    setTempState(prev => ({ ...prev, emailForSupport: e.target.value }))
                  }
                  error={errors?.emailForSupport}
                />
              </div>
            </div>
            <hr className={'text-gray/30'} />
            <div className={'flex flex-col gap-2'}>
              <div className={'flex gap-1.5'}>
                <RecaptchaIcon className={'h-[18px] w-[18px]'} />
                <Hint label={t('dashboard.settings.custom_domains.captcha_descriptions')}>
                  <span>{t('dashboard.settings.custom_domains.captcha_settings')}</span>
                </Hint>
              </div>
              <span>
                <TransLoco
                  t={t}
                  i18nKey={'dashboard.settings.custom_domains.captcha_text'}
                  components={{
                    a: (
                      <a
                        className="primary-link"
                        href={`https://www.google.com/recaptcha/about/`}
                        target={'_blank'}
                        rel={'noreferrer'}
                      />
                    ),
                  }}
                />
              </span>
              <div className={'flex flex-col lg:flex-row gap-2 lg:gap-10 [&>*]:flex-1'}>
                <FormInput
                  value={tempState.domainCaptchaSettings.openKey}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      domainCaptchaSettings: {
                        ...prev.domainCaptchaSettings,
                        openKey: e.target.value,
                      },
                    }))
                  }
                  label={t('dashboard.settings.custom_domains.captcha_site_key')}
                  error={errors.domainCaptchaSettings.openKey}
                />
                <FormInput
                  value={tempState.domainCaptchaSettings.privateKey}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      domainCaptchaSettings: {
                        ...prev.domainCaptchaSettings,
                        privateKey: e.target.value,
                      },
                    }))
                  }
                  label={t('dashboard.settings.custom_domains.captcha_secret_key')}
                  error={errors.domainCaptchaSettings.privateKey}
                />
              </div>
            </div>
            <hr className={'text-gray/30'} />
            <div className={'flex flex-col gap-2'}>
              <span>{t('dashboard.settings.custom_domains.facebook_conversion')}</span>
              <span>
                <TransLoco
                  t={t}
                  i18nKey={'dashboard.settings.custom_domains.facebook_description'}
                  components={{
                    a: (
                      <a
                        className="primary-link"
                        href={`https://www.facebook.com/events_manager2`}
                        target={'_blank'}
                        rel={'noreferrer'}
                      />
                    ),
                  }}
                />
              </span>
              <div className={'flex flex-col lg:flex-row gap-2 lg:gap-10 [&>*]:flex-1'}>
                <FormInput
                  value={tempState.facebookConversionPixelSettings.pixel}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      facebookConversionPixelSettings: {
                        ...prev.facebookConversionPixelSettings,
                        pixel: e.target.value,
                      },
                    }))
                  }
                  label={t('dashboard.settings.custom_domains.facebook_pixel_id')}
                  error={errors.facebookConversionPixelSettings.pixel}
                />
                <FormInput
                  value={tempState.facebookConversionPixelSettings.token}
                  onChange={e =>
                    setTempState(prev => ({
                      ...prev,
                      facebookConversionPixelSettings: {
                        ...prev.facebookConversionPixelSettings,
                        token: e.target.value,
                      },
                    }))
                  }
                  label={t('dashboard.settings.custom_domains.facebook_access_token')}
                  error={errors.facebookConversionPixelSettings.token}
                />
              </div>
            </div>
            <hr className={'text-gray/30'} />
            <ImageUploaderWithPreview
              label={
                <Hint label={t('dashboard.settings.custom_domains.logo_description')}>
                  <span>{t('global.logo')}</span>
                </Hint>
              }
              previewProps={{
                className: 'h-[100px] w-[100px]',
                src: tempState.logoFile?.path,
              }}
              onRemove={() =>
                setTempState(prev => {
                  return {
                    ...prev,
                    logoFile: null,
                  }
                })
              }
              imageLoaderProps={{
                fileSelectorDropZoneClassName: 'w-[200px] h-[100px]',
                onUploadSuccess: dataFile =>
                  setTempState(prev => ({ ...prev, logoFile: dataFile })),
                source: 'settings',
                error: errors.logoFile,
                label: (
                  <div className={'flex gap-1'}>
                    <span className={'text-blue'}>{t('global.choose_file')}</span>
                  </div>
                ),
              }}
            />
          </div>
          <div className="flex justify-center">
            <PrimaryButton
              width="large"
              onClick={e => {
                e.preventDefault()
                onEdit()
              }}
              isFetching={isFetching}
              type="submit"
            >
              {t('global.save')}
            </PrimaryButton>
          </div>
          {errors.common && (
            <p
              dangerouslySetInnerHTML={{ __html: errors.common }}
              className="mt-2 text-sm text-danger text-center [&>a]:primary-link"
            />
          )}
        </form>
      </Modal>
      <ConfirmationModal
        confirmationText={t('dashboard.settings.custom_domains.login_delete_confirmation')}
        title={t('confirmation_modal.default_title')}
        opened={isDeleteLoginOpen}
        onClose={() => setIsDeleteLoginOpen(false)}
        onConfirm={() => onRemoveLoginPage(domain)}
      />
      <ConfirmationModal
        confirmationText={t('dashboard.settings.custom_domains.not_found_delete_confirmation')}
        title={t('confirmation_modal.default_title')}
        opened={isDeleteNotFoundOpen}
        onClose={() => setIsDeleteNotFoundOpen(false)}
        onConfirm={() => onRemoveNotFoundPage(domain)}
      />
    </>
  )
}

export default DomainSettingsModal
