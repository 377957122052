import { Dispatch, SetStateAction, useCallback } from 'react'
import { OrderEnum, PaginationInterface } from 'shared/types/pagination-interface'
import { deepCompare } from 'shared/utils/deepCompare'
import { useSWRConfig } from 'swr'

interface useUpdateDataProps {
  api: string
  customApi?: string
  constantApi?: Array<{} | undefined | string>
  setPagination?: Dispatch<SetStateAction<PaginationInterface>>
}

export type UpdateDataFunctionType = (
  goToFirstPage?: boolean,
  data?: {},
  mutateApi?: { mutationData?: {}; onMutate: () => Promise<void> },
  additionalMutateApi?: { id?: number; onMutate: (apiKey: string) => Promise<void> },
) => Promise<void>

type CacheType = Map<string, { data: {} }>

export const useUpdateData = ({
  api,
  customApi,
  constantApi,
  setPagination,
}: useUpdateDataProps) => {
  const { cache, mutate } = useSWRConfig()
  const updateData: UpdateDataFunctionType = async (
    goToFirstPage,
    data,
    mutateApi,
    additionalMutateApi,
  ) => {
    const cacheArr = Array.from(cache as CacheType)
    const apiToUpdate: string[] | undefined = []
    const mutationKey = mutateApi
      ? cacheArr.find(el => deepCompare(el[1].data, mutateApi.mutationData!))
      : undefined

    const additionalMutationKey = additionalMutateApi
      ? cacheArr.find(el => {
          const item = el[1].data as unknown as { items: { id: number }[] } | undefined
          if (item?.items) {
            return item.items.some(element => element.id === additionalMutateApi.id)
          }
        })
      : undefined

    if (data) {
      if (goToFirstPage) {
        const singleApiList = cacheArr.find(
          (el: [string, {}]) =>
            el[0].includes(customApi ? customApi : `${api}/list`) &&
            !constantApi?.includes(el[0]) &&
            !el[0].includes('startFrom') &&
            !el[0].includes('$swr$'),
        )

        if (singleApiList) {
          apiToUpdate.push(singleApiList[0])
        }
      } else {
        const singleApiList = cacheArr.find(el => deepCompare(el[1].data, data))
        if (singleApiList) {
          apiToUpdate.push(singleApiList[0])
        }
      }
      const singleApiCount = cacheArr.find(
        (el: [string, {}]) => el[0].includes(`${api}/count`) && !el[0].includes('$swr$'),
      )
      if (singleApiCount) {
        apiToUpdate.push(singleApiCount[0])
      }
    }

    const apiToDelete = cacheArr
      .filter(
        el =>
          el[0].includes(api) &&
          (data ? true : !el[0].includes(`${api}/count`)) &&
          (mutateApi ? el !== mutationKey : true) &&
          (additionalMutateApi ? el !== additionalMutationKey : true) &&
          (constantApi
            ? constantApi.every(constant => {
                if (typeof constant === 'string') {
                  return !el[0].includes(constant)
                } else {
                  return !deepCompare(el[1].data, constant!)
                }
              })
            : true),
      )
      .map(el => el[0])

    if (goToFirstPage && setPagination) {
      setPagination(prev => ({
        ...prev,
        startFromId: undefined,
        order: OrderEnum.next,
      }))
    }
    apiToDelete.forEach(api => cache.delete(api))

    if (mutateApi) {
      await mutateApi.onMutate()
    }

    if (additionalMutateApi && additionalMutationKey) {
      await additionalMutateApi.onMutate(additionalMutationKey[0])
    }

    if (apiToUpdate) {
      await Promise.all(
        apiToUpdate.map(async api => {
          await mutate(api)
        }),
      )
    }
  }

  return useCallback(updateData, [api, cache, constantApi, customApi, mutate, setPagination])
}
