import { CUSTOM_DOMAINS_SETTINGS_API } from 'modules/profile/custom-domains-settings/api/custom-domain-api'
import { CustomDomainsSettingsApiInterface } from 'modules/profile/custom-domains-settings/types/custom-domains-settings-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { CountInterface, SwrPaginationInterface } from 'shared/types/pagination-interface'
import { baseFetcher } from 'shared/utils/http-client'
import useSWR from 'swr'

export function useCustomDomainsSettings({ pagination, limit }: SwrPaginationInterface) {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CustomDomainsSettingsApiInterface
  >({ method: RequestMethodsEnum.get })

  const { data: customDomains, mutate } = useSWR(() => {
    if (user && pagination.startFromId === undefined && limit) {
      return `${CUSTOM_DOMAINS_SETTINGS_API}/list?pagination[limit]=${limit}`
    } else if (user && pagination.startFromId && limit) {
      return `${CUSTOM_DOMAINS_SETTINGS_API}/list?pagination[startFrom]=${pagination.startFromId}&pagination[order]=${pagination.order}&pagination[limit]=${limit}`
    }
    return null
  }, fetcher)

  const { data: counter } = useSWR<CountInterface>(() => {
    if (user) {
      return `${CUSTOM_DOMAINS_SETTINGS_API}/count?pagination[limit]`
    }
    return null
  }, baseFetcher)

  return { customDomains, mutate, counter }
}
