import LocalizeNamespaces from 'shared/constants/localize-namespaces.mjs'

export const FileSelectorLoco = [LocalizeNamespaces.file_upload]

export enum FileSelectorVariantEnum {
  small,
  medium,
  big,
}

export const variantClassName = {
  [FileSelectorVariantEnum.small]: 'p-2',
  [FileSelectorVariantEnum.medium]: 'p-3',
  [FileSelectorVariantEnum.big]: 'p-10',
}
