export enum CustomDomainsStatusEnum {
  active = 'active',
  deactivated = 'deactivated',
  aws_certificate_dns_pending = 'aws_certificate_dns_pending',
  aws_main_dns_pending = 'aws_main_dns_pending',
  aws_caa_failed = 'aws_caa_failed',
  aws_cname_already_used = 'aws_cname_already_used',
  aws_remove_in_progress = 'aws_remove_in_progress',
  aws_cloudfront_disable_in_process = 'aws_cloudfront_disable_in_process',
  aws_removed = 'aws_removed',
}

export enum CustomDomainsStatusTitleEnum {
  aws_certificate_dns_pending = 'dashboard.settings.custom_domains.status.dns_pending',
  active = 'dashboard.settings.custom_domains.status.ready_to_use',
  deactivated = 'dashboard.settings.custom_domains.status.deactivated',
  aws_main_dns_pending = 'dashboard.settings.custom_domains.status.dns_pending',
  aws_caa_failed = 'dashboard.settings.custom_domains.status.aws_caa_failed',
  aws_cname_already_used = 'dashboard.settings.custom_domains.status.aws_cname_already_used',
  aws_remove_in_progress = 'dashboard.settings.custom_domains.status.aws_remove_in_progress',
  aws_cloudfront_disable_in_process = '',
  aws_removed = 'dashboard.settings.custom_domains.status.without_certificate',
}

export enum CustomDomainsStatusDescriptionEnum {
  deactivated = 'dashboard.settings.custom_domains.status.deactivated_description',
  aws_cname_already_used = 'dashboard.settings.custom_domains.status.aws_cname_already_used_description',
  aws_caa_failed = 'dashboard.settings.custom_domains.status.aws_caa_failed_description',
}
