// TODO: reuse it
import React from 'react'
import QuestionMarkIcon from 'shared/icons/question-mark-icon'
import Popover, { PopoverProps } from './popover'

export interface HintProps extends Omit<PopoverProps, 'children'> {
  children?: React.ReactNode
  color?: string
  className?: string
  showHint?: boolean
}

export const Hint = ({
  children,
  className,
  color = 'blue',
  showHint = true,
  ...props
}: HintProps) => (
  <div className={`flex gap-1.5 items-center ${className || ''}`}>
    {children}
    {showHint && (
      <Popover popoverClassName={'max-w-[200px] sm:max-w-[350px]'} {...props}>
        {show => (
          <QuestionMarkIcon
            className={`${
              show ? `fill-${color}` : 'fill-gray-100'
            } group-focus-visible:fill-${color} w-[16px] h-[16px]`}
          />
        )}
      </Popover>
    )}
  </div>
)
