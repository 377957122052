import process from 'process'
import React from 'react'
import FormInput from 'shared/components/form-input'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

interface FormEditSubdomainInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error: string
  onChangeValue: (value: string) => void
}
const parseValue = (value: string) => value.split(`.${process.env.NEXT_PUBLIC_SIO_HOST_NAME}`)

const FormEditSubdomainInput = ({ error, value, onChangeValue }: FormEditSubdomainInputProps) => {
  const { t } = useLocoTranslation()
  return (
    <FormInput
      label={t('global.subdomain')}
      value={parseValue(value as string)[0]}
      onChange={e => onChangeValue(e.target.value)}
      spellCheck={false}
      error={error}
      addonBefore={`${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://`}
      addonAfter={`.${process.env.NEXT_PUBLIC_SIO_HOST_NAME}`}
    />
  )
}

export default FormEditSubdomainInput
