import { Dispatch, SetStateAction } from 'react'
import { BadRequest } from 'shared/errors/bad-request'

export const fetchWithErrors = async <T>(
  callback: () => Promise<void>,
  setErrors: Dispatch<SetStateAction<{ [Property in keyof T]: string }>>,
  sideEffect?: () => void,
) => {
  try {
    await setErrors(prev => {
      const errors = { ...prev }
      Object.keys(prev).map(el => {
        const key = el as keyof T
        errors[key] = ''
      })
      return errors
    })
    await callback()
  } catch (e) {
    sideEffect && sideEffect()
    if (e instanceof BadRequest && e.errors.fields) {
      const fields = e.errors.fields
      await setErrors(prev => {
        const errors = { ...prev }
        Object.keys(prev).map(el => {
          const key = el as keyof T
          errors[key] = fields[el]?.join('')
        })
        return errors
      })
    }
    throw e
  }
}

export const fetchWithFieldAndCommonErrors: typeof fetchWithErrors = async <T>(
  ...args: Parameters<typeof fetchWithErrors<T>>
) => {
  const setErrors = args[1]

  try {
    await fetchWithErrors<T>(...args)
  } catch (e) {
    if (e instanceof BadRequest && e.errors.common) {
      const commonError = e.errors.common.join('')
      await setErrors(prev => {
        return { ...prev, common: commonError }
      })
    }
    throw e
  }
}

export const fetchWithFieldErrors = async <T>(
  callback: () => Promise<void>,
  setErrors: Dispatch<SetStateAction<{ fields: { [Property in keyof T]: string } }>>,
  sideEffect?: () => void,
) => {
  try {
    await setErrors(prev => {
      const errors = { ...prev }
      Object.keys(prev.fields).map(el => {
        const key = el as keyof T
        errors.fields[key] = ''
      })
      return errors
    })
    await callback()
  } catch (e) {
    sideEffect && sideEffect()
    if (e instanceof BadRequest && e.errors.fields) {
      const fields = e.errors.fields
      await setErrors(prev => {
        const errors = { ...prev }
        Object.keys(prev.fields).map(el => {
          const key = el as keyof T
          errors.fields[key] = fields[el]?.join('')
        })
        return errors
      })
    }
    throw e
  }
}
