// move it to the image-uploader/index.tsx
import React, { useState } from 'react'
import UploadIcon from 'shared/icons/upload-icon'
import { DataFile } from 'shared/types/datafile-type'
import { fileInputAcceptTypes } from 'shared/utils/file-input-accept-types'
import { FileSelector, FileSelectorProps } from '../file-selector'
import {
  ImageCropperAndUploader,
  ImageCropperAndUploaderProps,
} from './components/image-cropper-and-uploader'

export type ImageUploaderProps = Pick<
  FileSelectorProps,
  'acceptTypes' | 'label' | 'variant' | 'icon'
> &
  Pick<ImageCropperAndUploaderProps, 'source' | 'type' | 'imageCropperProps'> & {
    onUploadSuccess: (dataFile: DataFile) => void
    onUnset?: () => void
    fileSelectorClassName?: string
    fileSelectorDropZoneClassName?: string
    fileSelectorChildren?: React.ReactNode
    fileSelectorDisabled?: boolean
    error?: string
  }
function ImageUploader({
  onUploadSuccess,
  icon = <UploadIcon />,
  fileSelectorClassName,
  fileSelectorDropZoneClassName,
  fileSelectorChildren,
  fileSelectorDisabled,
  source,
  variant,
  acceptTypes = fileInputAcceptTypes.image,
  label,
  type,
  imageCropperProps: imageCropperProps,
}: React.PropsWithChildren<ImageUploaderProps>) {
  const [imageFile, setImageFile] = useState<File>()
  const [isOpened, setIsOpened] = useState(false)

  const onAddFile = (file: File) => {
    setImageFile(file)
    setIsOpened(true)
  }

  const onClose = () => {
    setImageFile(undefined)
  }

  return (
    <>
      <FileSelector
        acceptTypes={acceptTypes}
        className={fileSelectorClassName}
        dropZoneClassName={fileSelectorDropZoneClassName}
        label={label}
        variant={variant}
        icon={icon}
        onSelectFile={onAddFile}
        disabled={fileSelectorDisabled}
      >
        {fileSelectorChildren}
      </FileSelector>

      <ImageCropperAndUploader
        imageFile={imageFile}
        source={source}
        type={type}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        onClose={onClose}
        onUploadSuccess={onUploadSuccess}
        imageCropperProps={imageCropperProps}
      />
    </>
  )
}

export default ImageUploader
